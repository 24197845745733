import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MuzicaPage = () => (
  <Layout>
    <SEO title="Muzica la nuntă" />
    <div className="drawer article-content">
      <h1 className="article-title">Muzica la nuntă</h1>

      <p>
        Unul dintre cele mai importante aspecte de la nuntă este muzica. Oamenii
        poate vor uita ce flori ai ales, ce model de rochie ai purtat, însă nu
        vor uita niciodată cât de bine s-au distrat. Și cum te poți distra la o
        nuntă dacă nu pe ringul de dans. Când vei începe să te gândești cum să
        alegi muzica pentru nuntă, cu siguranță te vei gândi inițial ce muzică vă
        place vouă, însă va trebui să te gândesți (măcar puțin) și la restul
        invitaților. Formație sau DJ?
      </p>

      <h3 className="paragh-title">DJ</h3>
      <p>
        Este mult mai potrivit pentru o nuntă mai mică și costurile fiind mult
        mai mici. De asemenea, DJ-ul este varianta ideală și în cazul în care vă
        doriți mai multe stiluri muzicale, iar dacă este și MC și va
        interacționa cu invitații sigur va ieși o super petrecere.
      </p>

      <h3 className="paragh-title">Formația</h3>
      <p>
        Muzica live va fi mult mai apreciată, calitatea sunetului este mult mai
        bună, iar dacă este una din formațiile pe care voi le apreciați,
        sentimentul de a cânta pentru voi o sa fie uimitor. Costurile însă sunt
        ceva mai mari. Dacă alegeți o formație este important să vă asigurați că
        pot cânta mai multe genuri muzicale și că există și un DJ în formație,
        astfel încât în pauzele formației să nu se folosească de un playlist și
        scena să rămână goală. Indiferent dacă alegerea este DJ sau formație
        trebuie să vă documentați înainte. Sigur că varianta ideală și cea mai
        simplă este să îi fi auzit la o altă nuntă, însă dacă nu sunteți în
        situația respectivă citiți recenzii, formația asultați-o live înainte de
        a semna, cereți detalii despre program (să nu aflați ulterior că au un
        moment țigănesc/latino în program pe care voi nu vi-l doriți și la care
        nu vor să renunțe) și bineînțeles cereți detalii despre plată. Cereți
        detalii și despre scenă și lumini, dacă se ocupă ei de aceste aspecte și
        dacă presupun costuri suplimentare.
      </p>
      <p>
        O variantă de mijloc ar fi DJ și formație, în cazul în care vrei
        neapărat să cânte trupa voastră preferată sau cineva cunoscut, care are
        un program artistic mai scurt (de obicei 2 reprize de câte 45 min) și
        atunci puteți opta pentru un DJ si un moment live mai special. Sfatul
        este să discutați cu ei și live, să îi cunoașteți, să îi ascultați și să
        fiți siguri că puteți colabora frumos cu ei, astfel încât fiecare
        furnizor să știe exact ce are de făcut în ziua nunții, cum să se
        descurce dacă apar ceva probleme și voi să vă bucurați de ziua voastră
        specială.
      </p>

      <img src="https://images.unsplash.com/photo-1482575832494-771f74bf6857?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" />

      <div className="nav-section">
        <Link to="/florile">◀ Cum alegem florile ?</Link>
        <Link to="/invitatiile">▶ Cum alegem invitațiile de nuntă?</Link>
      </div>
    </div>
  </Layout>
)

export default MuzicaPage
